
import { computed, defineComponent, reactive } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import { InputTypes } from "@/store/enums/StoreEnums";
import { translate, translateKey } from "@/hooks/TranslateService";
import SurveyService from "@/core/services/SurveyService";
import store from "@/store";
import IntegerGenderField from "@/components/wizard/steps/input_fields/IntegerGenderField.vue";
import SelectYesNoField from "@/components/wizard/steps/input_fields/SelectYesNoField.vue";

export default defineComponent({
  name: "step-1",
  props: [
    "head_line",
    "input_values_reduce",
    "input_values_in_step",
    "survey_obj",
    "step",
    "errors",
  ],
  components: {
    Field,
    ErrorMessage,
    IntegerGenderField,
    SelectYesNoField,
  },
  setup(props) {
    const getInputByIdGroup = computed(
      (
        step: number,
        id_input: number,
        array_to_filter: Array<any>
      ): Array<any> => {
        return array_to_filter.filter(
          (item) => item.input.set_id === step && item.input.id == id_input
        );
      }
    );
    const currentUser = reactive(computed(() => store.getters.currentUser));

    const setsHeaderList = reactive(
      computed(() => store.getters.getSurveySetsHeaderList)
    );
    const comparison = (value, value2) => {
      return value === value2;
    };

    const getErrors = (id) => {
      if (props.errors) return props.errors.filter((item) => item.id == id);
      else return null;
    };

    const messages_survey = [
      "SURVEY_1_MESSAGE_COMPOSIZIONE_DIPENDENTI",
      "SURVEY_2_3_MESSAGE_COMPOSIZIONE_CDA",
      "SURVEY_2_3_MESSAGE_COMPOSIZIONE_CDA",
      "SURVEY_4_MESSAGE_COMPOSIZIONE_TOP_MANAGEMENT",
      "SURVEY_5_MESSAGE_SUCCESSION_PLAN",
      "SURVEY_6_MESSAGE_COMPOSIZIONE_NUOVI_DIPENDENTI",
      "SURVEY_7_MESSAGE_TURNOVER_DIPENDENTI",
      "SURVEY_8_MESSAGE_COMPOSIZIONE_DIPENDENTI",
      "SURVEY_9_MESSAGE_CONGEDO_PARENTALE",
      "SURVEY_10_MESSAGE_AVANZAMENTO_CARRIERA",
      "SURVEY_11_MESSAGE_DIFFERENZE_RETRIBUTIVE",
      "SURVEY_12_MESSAGE_FORME_DI_LAVORO_FLESSIBILE",
    ];

    return {
      InputTypes,
      currentUser,
      translate,
      translateKey,
      getInputByIdGroup,
      SurveyService,
      comparison,
      messages_survey,
      setsHeaderList,
      getErrors,
    }; // anything returned here. will be available for the rest of the component
  },
});
